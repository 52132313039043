import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";

import FNOLScreen from "app/page";
import SuccessScreen from "app/success";

import RootLayout from "./app/layout";

function App() {
  const baseProps = {};

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<FNOLScreen {...baseProps} />}
        />
        <Route
          path="*"
          element={
            <RootLayout>
              <div>Not Found</div>
            </RootLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
