import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  keyColumn,
  intColumn,
  floatColumn,
  dateColumn,
  isoDateColumn,
  percentColumn,
  createTextColumn,
  Column,
  CellProps,
  CellComponent,
} from "react-datasheet-grid";
// import { useState } from "react";
import { Section } from "../layout";
import "react-datasheet-grid/dist/style.css";
import {CurrencyFormatter, DateFormatter} from "../../utils";
import Select from "react-select";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { FieldLabelComponent } from "../hook-form";
import { isEmpty } from "lodash";
// import { AlertRenderer } from "../alerts";
// import { ButtonTabs } from "../button-tabs";
import { FormContentRenderer } from "../hook-form";
import { FieldTypes } from "../../@types";
// import Dropzone from "../dropzone/dropzone";
import { Modal } from "../modal";
import VinDecodeModalContent from "./vin_modal";
import { AlertRenderer } from "../alerts";

interface IDataSheetGridColumn {
  name: string;
  label: string;
  component: any;
  id?: string;
  grow?: number;
  minWidth?: number;
  maxWidth?: number;
  disabled?: boolean;
  options?: Array<{ label: string; value: string | number | boolean }>;
}

interface ISelectOption {
  label: string;
  value: string | number | boolean;
}

interface ISelectColumnOptions {
  choices: Array<ISelectOption>;
  onChange: (idx: number, data: any, mappedField: string) => void;
  disabled?: boolean;
  mappedField: string;
}

// @ts-ignore
const DataSheetGridSelect:
  | CellComponent<string | null, ISelectColumnOptions>
  | undefined = React.memo(
  (props: CellProps<string | ISelectOption | null, ISelectColumnOptions>) => {
    const { columnData, rowData, focus, active, stopEditing, rowIndex } =
      props || {};

    const ref = useRef<Select>(null);
    useLayoutEffect(() => {
      if (focus) {
        // @ts-ignore
        ref?.current?.focus();
      } else {
        // @ts-ignore
        ref?.current?.blur();
      }
    }, [focus]);

    return (
      <Select
        // @ts-ignore
        ref={ref}
        styles={{
          container: (provided) => ({
            ...provided,
            flex: 1, // full width
            alignSelf: "stretch", // full height
            pointerEvents: focus ? undefined : "none",
          }),
          control: (provided) => ({
            ...provided,
            height: "100%",
            border: "none",
            boxShadow: "none",
            background: "none",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            opacity: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
          placeholder: (provided) => ({
            ...provided,
            opacity: active ? 1 : 0,
          }),
        }}
        isDisabled={columnData?.disabled}
        menuIsOpen={focus}
        menuPortalTarget={document.body}
        value={columnData?.choices.find(({ value }) => {
          const comparator =
            typeof rowData === "string" || typeof rowData === "number"
              ? rowData
              : rowData?.value;
          return value === comparator;
        })}
        onChange={(selectedVal: any) =>
          columnData?.onChange(rowIndex, selectedVal, columnData?.mappedField)
        }
        options={columnData?.choices}
        onMenuClose={() => stopEditing({ nextRow: false })}
      />
    );
  }
) as CellComponent<string | null, ISelectColumnOptions> | undefined;

const selectColumn = (selectProps: {
  onChange: (rowIdx: number, selectVal: any, attr: string) => void;
  mappedField: string | undefined;
  choices: undefined | any[];
}): Column<string | null, ISelectColumnOptions> => {
  return {
    component: DataSheetGridSelect,
    // @ts-ignore
    columnData: selectProps,
    disableKeys: true,
    keepFocus: true,
    // @ts-ignore
    disabled: selectProps?.disabled,
    deleteValue: () => null,
    copyValue: ({ rowData }: any) =>
      // @ts-ignore
      selectProps?.choices.find((choice: any) => choice?.value === rowData)
        ?.value ?? null,
    pasteValue: ({ value }: any): any =>
      // @ts-ignore
      selectProps?.choices.find((choice: any) => choice?.value === value)
        ?.value ?? null,
  };
};

const kaffiDateParser = (value: string) => {
      const date = new Date(value)
      if (("" + date) === "Invalid Date") {
        return ""
      }
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      return `${year}-${formattedMonth}-${formattedDay}`
    }

export const DataSheetGridComponents = {
  checkbox: checkboxColumn,
  input: textColumn,
  int: intColumn,
  float: floatColumn,
  date: dateColumn,
  isoDate: isoDateColumn,
  kaffiDate: createTextColumn({
    parsePastedValue: kaffiDateParser,
    parseUserInput: kaffiDateParser,
    formatBlurredInput: (value: string) => {
      return !isEmpty(value) ? DateFormatter(value, false, true, true) : "";
    },
  }),
  percent: percentColumn,
  // booleanCheckmark:
  dollarValue: createTextColumn({
    formatBlurredInput: (value: string | number) => {
      const val =
        typeof value === "string" ? value?.replaceAll(",", "") : value;
      return CurrencyFormatter(Number(val), 1);
    },
  }),
  select: selectColumn,
};

const DataSheetGridComponent = (props: any) => {
  const {
    headers,
    data = [],
    onChange,
    addRowsComponent = false,
    handleAddRow,
    formField,
    handleDeleteRow,
    ...restOfProps
  } = props || {};

  // const [currentTab, setCurrentTab] = useState(0);

  const {
    label,
    firstColumn,
    emptyNewRow,
    name,
    // getTemplate,
    // validationCall,
    // successValidationCall,
    vinSearchProps,
  } = formField || {};
  const { key, value } = firstColumn || {};

  useEffect(() => {
    if (key && value) {
      if (handleAddRow) {
        value?.forEach((val: string) => {
          const dataKeys = data?.map((e: any) => e?.[key]) || [];
          if (!dataKeys?.includes(val)) {
            handleAddRow({ ...emptyNewRow, [key]: val });
          }
        });
      }
    } else if (!data || isEmpty(data)) {
      if (handleAddRow && name !== "vehicles") {
        handleAddRow();
      }
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const required =
    name === "vehicles"
      ? ["vin", "vehicle_rate_class", "make", "model", "year"]
      : name === "drivers"
      ? ["dl_number", "dl_state", "first_name", "last_name"]
      : [];
  const updatedHeaders = headers?.filter(
    (header: any) => header?.name !== "cell_phone" || header?.name !== "email"
  );
  const columns = updatedHeaders?.map((h: IDataSheetGridColumn) => {
    const isArray = Array.isArray(h);
    let header;
    if (isArray) {
      header = h[0];
    } else {
      header = h;
    }
    const {
      name,
      component,
      label,
      ...restOfColumn
    } = header || {};
    const colWidth =
      name === "vehicle_rate_class" ? { minWidth: 180 } : { minWidth: 50 };
    return {
      ...keyColumn(name, component),
      title: required?.includes(name) ? label + " *" : label,
      ...restOfColumn,
      ...colWidth,
      disabled: key === name,
    };
  });

  // const handleTemplateDownload = async () => {
  //   try {
  //     const response = getTemplate && (await getTemplate());
  //
  //     if (!response.ok) {
  //       throw new Error("Failed to download the template");
  //     }
  //
  //     const blob = new Blob([response?.data], {
  //       type: "application/octet-stream",
  //     });
  //
  //     // Create a URL for the Blob
  //     const url = window.URL.createObjectURL(blob);
  //
  //     const contentDisposition = response?.headers?.["content-disposition"];
  //     let filename = "";
  //
  //     // Parse the filename from the Content-Disposition header
  //     if (contentDisposition) {
  //       const filenameMatch = contentDisposition.match(
  //         /filename\*=utf-8''(.+)/
  //       );
  //       if (filenameMatch) {
  //         // URL decode the filename
  //         filename = decodeURIComponent(filenameMatch[1]);
  //       } else {
  //         // Handle non-encoded filenames if necessary
  //         const fallbackFilenameMatch =
  //           contentDisposition.match(/filename="([^"]+)"/);
  //         if (fallbackFilenameMatch) {
  //           filename = fallbackFilenameMatch[1];
  //         }
  //       }
  //     }
  //
  //     // Create a link element
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = filename;
  //     document.body.appendChild(a);
  //     a.click();
  //
  //     // Clean up and revoke the Blob URL
  //     a.remove();
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  const showGrid = name === "vehicles" ? !isEmpty(data) : true;
  const showModal =
    !isEmpty(vinSearchProps?.vin_list) && vinSearchProps?.succeeded;

  const hasError = (rowData: any) => {
    const rowDataKeys = (!isEmpty(rowData) && Object.keys(rowData)) || [];
    let err = false;
    rowDataKeys?.forEach((k: string) => {
      if (required?.includes(k)) {
        if (!rowData?.[k] || isEmpty(rowData?.[k] + "")) {
          err = true;
        }
      }
    });
    return err;
  };

  return (
    <Section id={formField?.name}>
      {label && (
        <div style={{ marginBottom: "15px" }}>
          <FieldLabelComponent label={label} />
        </div>
      )}
      {/*{getTemplate && (*/}
      {/*  <ButtonTabs*/}
      {/*    tabs={[*/}
      {/*      {*/}
      {/*        key: 0,*/}
      {/*        label: "UPLOAD SCHEDULE",*/}
      {/*        onClick: () => setCurrentTab(0),*/}
      {/*      },*/}
      {/*      { key: 1, label: "WEB EDIT", onClick: () => setCurrentTab(1) },*/}
      {/*    ]}*/}
      {/*    styleTab={{ padding: "10px 12px" }}*/}
      {/*    style={{ marginBottom: "10px" }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{getTemplate && currentTab === 0 && (*/}
      {/*  <Section>*/}
      {/*    <AlertRenderer*/}
      {/*      severity="info"*/}
      {/*      message="A template is available for download. Please use this template if you wish to use the upload function."*/}
      {/*      style={{ marginTop: "12px" }}*/}
      {/*    />*/}
      {/*    <AlertRenderer*/}
      {/*      severity="warning"*/}
      {/*      message="If you upload the schedule using the provided template, the data therein will replace any existing web edits you have made, if any."*/}
      {/*      style={{ marginTop: "12px" }}*/}
      {/*    />*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        cursor: "pointer",*/}
      {/*        padding: "20px 0",*/}
      {/*        color: "blue",*/}
      {/*        fontSize: "0.9em",*/}
      {/*      }}*/}
      {/*      onClick={handleTemplateDownload}*/}
      {/*    >*/}
      {/*      <u>DOWNLOAD TEMPLATE</u>*/}
      {/*    </span>*/}
      {/*    <Dropzone*/}
      {/*      multiple={false}*/}
      {/*      validationCall={validationCall}*/}
      {/*      successValidationCall={successValidationCall}*/}
      {/*    />*/}
      {/*  </Section>*/}
      {/*)}*/}
      {/*((getTemplate && currentTab === 1) || !getTemplate) && */}
      {name === "vehicles" && (
        <FormContentRenderer
          formFields={[
            {
              name: "_vin_list",
              label: "Add vehicle(s) by VIN",
              hint: "Enter the VIN(s) you would like to add. When adding multiple VINs, please separate with a comma or a new line.",
              fieldType: FieldTypes.input_search_textarea,
              loading: vinSearchProps?.loading,
              searchSucceeded: vinSearchProps?.succeeded,
              onSearchClick: (
                vin_list: string,
                successCallback: () => void
              ) => {
                if (vinSearchProps?.onSearchClick) {
                  vinSearchProps?.onSearchClick(vin_list);
                }
              },
            },
          ]}
          hideFormSubmit={true}
          renderWithoutFormTags={true}
        />
      )}
      {showModal && (
        <Modal open={showModal} title="Add vehicles to schedule">
          <VinDecodeModalContent vinSearchProps={vinSearchProps} />
        </Modal>
      )}
      {showGrid && (
        <div>
          <AlertRenderer
            severity="info"
            message="This table supports copy/paste from external sources such as Excel, as well as copy/paste from one cell to others."
            style={{ margin: "5px 0" }}
          />
          <AlertRenderer
            severity="info"
            message="To copy a cell's value to cells below it, select the cell, then drag the bottom right corner down."
            style={{ margin: "5px 0" }}
          />
        </div>
      )}
      {showGrid && (
        <DataSheetGrid
          rowClassName={({ rowData }) => {
            const invalidRow = hasError(rowData);
            return invalidRow ? "row-error" : null;
          }}
          // @ts-ignore
          value={data || []}
          onChange={onChange}
          // @ts-ignore
          columns={columns}
          addRowsComponent={
            firstColumn || name === "vehicles" ? false : addRowsComponent
          }
          style={{ minHeight: "200px", marginTop: "10px" }}
          {...restOfProps}
          stickyRightColumn={
            handleDeleteRow && !firstColumn
              ? {
                  component: ({ rowIndex, deleteRow }) => (
                    <button
                      style={{
                        padding: "6px 8px",
                        marginLeft: "5px",
                        backgroundColor: "var(--primary1)",
                        border: "none",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      onClick={() => {
                        handleDeleteRow(rowIndex);
                        deleteRow();
                      }}
                    >
                      X
                    </button>
                  ),
                }
              : false
          }
        />
      )}
    </Section>
  );
};

export default DataSheetGridComponent;
