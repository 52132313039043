import {
  CoverLayout,
} from "ui-bank";
import { getLogo } from "./page";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../reducers/config/hooks";
import baseActions from "reducers/base"

export default function Home() {
  const [numSec, setNumSec] = useState(60)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (numSec === 0) {
      dispatch(baseActions.resetForm())
      dispatch(baseActions.getFormConfigRequest());
    } else {
      const timer = setInterval(() => {
        setNumSec((prevCounter) => prevCounter - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numSec]);

  return (
    <CoverLayout
      style={{maxWidth: window.innerWidth > 400 ? "95%" : "88%"}}
    >
      <img
        src={getLogo()}
        alt="logo"
        height={125}
        style={{marginTop: window.innerWidth < 500 ? "2em" : 0}}
      />
      <div style={{marginTop: "1.5em", textAlign: "center"}}>
        <h1>You're all set!</h1>
        <span>We have received your First Notice of Loss submission. You can safely close this window.</span>
      </div>
      <div style={{marginTop: "1.5em", textAlign: "center"}}>
        <h3>{`This page will reset in ${numSec} seconds...`}</h3>
      </div>
    </CoverLayout>
  );
}
