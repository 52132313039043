import styled from "styled-components";
import { topBarHeight } from "../../utils/constants";
import { Section, SectionLayoutCss } from "../layout";
import Avatar from "@mui/material/Avatar";

const StyledTopBar = styled(Section)`
  width: 100%;
  height: ${({ height }: any) => height ? height : topBarHeight }px;
  box-shadow: var(--base-box-shadow);
  background-color: ${({ bg_color }: any) => bg_color || "white"};
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledContentWrapper = styled(Section)`
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $justifyContent }: any) => $justifyContent || "space-between"};
  max-width: 1500px;
  ${SectionLayoutCss}
`;

export const TopBar = ({ currentUser, showSecondaryMenu = false, secondaryMenuItems = [], currentActiveMenuItem = 0 }: any) => {
  let updated_user = currentUser;
  if (currentUser?.includes(":")) {
    const [prefix, email] = currentUser?.split(":");
    updated_user = email;
  }
  return (
    <Section>
      <StyledTopBar>
        <StyledContentWrapper>
          <Section
            $alignItems="flex-start"
            onClick={() => (window.location.href = window.location.origin)}
            style={{ cursor: "pointer", paddingTop: "4px" }}
          >
            <img
              src="https://storage.googleapis.com/wls-assets/logos/wtu_black.png"
              alt="wholesure"
              height="70"
              width="auto"
            />
          </Section>
          <Section
            $justifyContent="flex-end"
            $alignItems="center"
            $flexDirection="row"
          >
            <Avatar sx={{ width: 28, height: 28 }} />
            <span style={{ marginLeft: "10px" }}>{updated_user}</span>
          </Section>
        </StyledContentWrapper>
      </StyledTopBar>
      {showSecondaryMenu && <StyledTopBar bg_color="#EEEEEE" height={55}>
        <StyledContentWrapper $justifyContent="space-around">
          {secondaryMenuItems?.map((e: any) => {
            const isActive = e?.idx === currentActiveMenuItem;
            return <span style={{ fontWeight: isActive ? "bold" : "normal", cursor: "pointer", textDecoration: isActive ? "underline" : "none" }} key={e?.idx} onClick={e?.onClick}>{isActive ? e?.label?.toUpperCase() : e?.label}</span>
            })
          }
        </StyledContentWrapper>
      </StyledTopBar>}
    </Section>
  );
};
