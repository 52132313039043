export const FormatPhone = (phone: string) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phone;
};

export const FormatFEIN = (fein: string) => {
  var cleaned = ("" + fein).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{2})(\d{7})$/);
  if (match) {
    return [match[1], "-", match[2]].join("");
  }
  return fein;
};
