import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledButton } from "../buttons";
import {
  StyledFormContentWrapper,
  StyledFormSectionTitle,
} from "./styled-form-components";
import { FieldTypes, IField, IContentRenderer } from "../../@types";
import {
  InputField,
  InputSearchField,
  FileUpload,
  Textarea,
  DropdownSelect,
  DropdownMultiSelect,
  AsyncDropdownSelect,
  CustomDatePicker,
  SwitchButton,
  InputList,
  PercentageTable,
  Contact,
  Entity,
} from "./form-components";
import { DataSheetGrid } from "../data-sheet-grid";
import { Section } from "../layout";
import { debounce, isEmpty } from "lodash";
import { getDefaultFormSchema } from "../../@validators/yup/utils";
import DropzoneComponent from "./form-components/dropzone";
// import { getRequiredFieldsListFromSchema } from "../../util";

import "react-datepicker/dist/react-datepicker.css";

interface IContentRendererExtended extends IContentRenderer {
  onSubmit: (formData: any) => void;
  handleSaveErrors: () => void;
  handleAutosave: (data: object) => void;
  saveOnFieldChange: (data: any) => void;
  validateOnEvent: () => void;
  debouncedAutosave: any;
  requiredFields: Array<string>;
  composedFormContentWrapperStyle: object;
  formProps: any;
  renderWithoutFormTags?: boolean;
  style?: object;
}
const ContentRenderer = (props: IContentRendererExtended) => {
  const {
    onFormSubmit,
    composedFormContentWrapperStyle,
    formFields = [],
    formProps: {
      watch,
      errors,
      touchedFields,
      register,
      setFocus,
      setValue,
      getValues,
      trigger,
      getFieldState,
      reset,
      control,
      handleSubmit,
    },
    debouncedAutosave,
    validateOnEvent,
    initialValues = {},
    handleAutosave,
    hideFormSubmit,
    getCustomButtonRow,
    formSubmitLabel = "",
    formSubmitLoading = false,
    renderWithoutFormTags,
    onSubmit,
    style = {},
    handleSaveErrors,
    additionalProps,
    dropzoneProps,
    handleUpdateEntity,
    formData,
  } = props;

  const autoGrow = (element: any) => {
    if (element) {
      element.target.style.height = "5px";
      element.target.style.height = `${element.target.scrollHeight}px`;
    }
  };

  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <>
      <StyledFormContentWrapper
        $flexDirection="row"
        $flexWrap="wrap"
        $justifyContent="space-between"
        style={{
          ...composedFormContentWrapperStyle,
          ...style,
        }}
      >
        {formFields?.map((formField: IField, idx: number) => {
          const { fieldType, name, rules, customOnRemoveItem } = formField;
          const nonControlledProps = {
            watch,
            errors,
            touchedFields,
            register,
            rules,
            setValue,
            getValues,
            trigger,
            getFieldState,
            handleAutosave: debouncedAutosave,
            validateOnEvent,
            reset,
            onFormSubmit,
          };
          switch (fieldType) {
            case FieldTypes.input_search_textarea:
            case FieldTypes.input_search:
              const initialValue = initialValues[name];
              const hasInitialValue = initialValue && !isEmpty(initialValue);
              return (
                <InputSearchField
                  key={`${name}${idx}`}
                  {...formField}
                  {...nonControlledProps}
                  renderTextarea={
                    fieldType === FieldTypes.input_search_textarea
                  }
                  hasInitialValue={hasInitialValue}
                />
              );
            case FieldTypes.textarea:
              return (
                <Textarea
                  key={`${name}${idx}`}
                  {...formField}
                  onInput={autoGrow}
                  {...nonControlledProps}
                />
              );
            case FieldTypes.input_list:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  name={name}
                  // @ts-ignore
                  rules={rules}
                  render={(props: any) => (
                    <InputList
                      key={idx}
                      {...formField}
                      {...nonControlledProps}
                      {...props}
                    />
                  )}
                />
              );
            case FieldTypes.grid:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  name={name}
                  // @ts-ignore
                  rules={rules}
                  render={(props: any) => {
                    const {
                      headers,
                      addRowsComponent,
                      onFieldChange,
                      name,
                      handleAddRow,
                      handleDeleteRow,
                    } = formField || {};
                    return (
                      <DataSheetGrid
                        // @ts-ignore
                        headers={headers}
                        data={initialValues[name]}
                        onChange={(gridData: any) => {
                          onFieldChange && onFieldChange(gridData);
                        }}
                        addRowsComponent={addRowsComponent}
                        handleAddRow={handleAddRow}
                        formField={formField}
                        handleDeleteRow={handleDeleteRow}
                      />
                    );
                  }}
                />
              );
            case FieldTypes.dollar_table:
            case FieldTypes.percentage_table:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  name={name}
                  // @ts-ignore
                  rules={rules}
                  render={(props: any) => (
                    <PercentageTable
                      key={name}
                      {...formField}
                      {...nonControlledProps}
                      {...props}
                      dollarTable={fieldType === FieldTypes.dollar_table}
                    />
                  )}
                />
              );
            case FieldTypes.select:
              const { onFieldChange } = formField;
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  name={name}
                  // @ts-ignore
                  rules={rules}
                  render={(props) => (
                    <DropdownSelect
                      key={`${name}${idx}`}
                      {...formField}
                      {...props}
                      errors={errors}
                      touchedFields={touchedFields}
                      nonControlledProps={nonControlledProps}
                      handleUpdateEntity={handleUpdateEntity}
                      formData={formData}
                      onChange={(value: any) => {
                        if (props?.field?.onChange) {
                          props?.field?.onChange(value);
                        }
                        if (onFieldChange) {
                          onFieldChange(value);
                        }
                        debouncedAutosave({ [name]: value });
                        if (validateOnEvent) {
                          validateOnEvent();
                        }
                      }}
                    />
                  )}
                />
              );
            case FieldTypes.multi_select:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => (
                    <DropdownMultiSelect
                      key={idx}
                      {...formField}
                      {...props}
                      errors={errors}
                      touchedFields={touchedFields}
                      nonControlledProps={nonControlledProps}
                      onChange={(value: any, _ref: any) => {
                        const { action, removedValue } = _ref || {};
                        if (props?.field?.onChange) {
                          props?.field?.onChange(value);
                        }
                        debouncedAutosave({ [name]: value });
                        if (action === "remove-value") {
                          customOnRemoveItem &&
                            customOnRemoveItem(removedValue);
                        }
                      }}
                    />
                  )}
                />
              );
            case FieldTypes.date:
            case FieldTypes.date_select:
            case FieldTypes.date_time_select:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => (
                    <CustomDatePicker
                      key={idx}
                      {...formField}
                      {...props}
                      watch={watch}
                      errors={errors}
                      touchedFields={touchedFields}
                      setFocus={setFocus}
                      nonControlledProps={nonControlledProps}
                      showTimeInput={fieldType === FieldTypes.date_time_select}
                      onChange={(value: any) => {
                        if (props?.field?.onChange) {
                          props?.field?.onChange(value);
                        }
                        handleAutosave({ [name]: value });
                      }}
                    />
                  )}
                />
              );
            case FieldTypes.entity:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => {
                    return (
                      <Entity
                        key={idx}
                        {...formField}
                        {...props}
                        entities={formData?.[name]}
                        errors={errors}
                        touchedFields={touchedFields}
                        nonControlledProps={nonControlledProps}
                        additionalProps={additionalProps}
                        handleUpdateEntity={handleUpdateEntity}
                      />
                    );
                  }}
                />
              );
            case FieldTypes.contact:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => {
                    return (
                      <Contact
                        key={idx}
                        {...formField}
                        {...props}
                        initialValue={initialValues[name]}
                        errors={errors}
                        touchedFields={touchedFields}
                        nonControlledProps={nonControlledProps}
                        additionalProps={additionalProps}
                      />
                    );
                  }}
                />
              );
            case FieldTypes.address:
            case FieldTypes.autocomplete:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => (
                    <AsyncDropdownSelect
                      key={idx}
                      {...formField}
                      {...props}
                      isAutocomplete={true}
                      errors={errors}
                      touchedFields={touchedFields}
                      watch={watch}
                      setSubmitDisabled={setSubmitDisabled}
                      onChange={(value: any) => {
                        if (props?.field?.onChange) {
                          props?.field?.onChange(value);
                        }
                        handleAutosave({ [name]: value });
                      }}
                    />
                  )}
                />
              );
            case FieldTypes.checkbox:
            case FieldTypes.toggle:
            case FieldTypes.switch:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => {
                    return (
                      <SwitchButton
                        key={idx}
                        {...formField}
                        {...props}
                        formData={!isEmpty(formData) ? formData : (formField?.formData || {})}
                        nonControlledProps={nonControlledProps}
                        dropzoneProps={dropzoneProps}
                        handleUpdateEntity={handleUpdateEntity}
                        initialValues={initialValues}
                        onChange={(value: any) => {
                          if (props?.field?.onChange) {
                            props?.field?.onChange(value);
                          }
                          handleAutosave({ [name]: value });
                        }}
                      />
                    );
                  }}
                />
              );
            case FieldTypes.dropzone:
              return (
                <Controller
                  key={`${name}${idx}`}
                  control={control}
                  // @ts-ignore
                  rules={rules}
                  name={name}
                  render={(props) => {
                    return (
                      <DropzoneComponent
                        key={idx}
                        {...dropzoneProps}
                        mappedField={formField?.name}
                        {...formField}
                        {...props}
                      />
                    );
                  }}
                />
              );
            // case FieldTypes.checkbox:
            //   return (
            //     <Controller
            //       key={`${name}${idx}`}
            //       control={control}
            //       // @ts-ignore
            //       rules={rules}
            //       name={name}
            //       render={(props) => {
            //         return (
            //           <CheckboxButton
            //             key={idx}
            //             {...formField}
            //             {...props}
            //             nonControlledProps={nonControlledProps}
            //             reset={reset}
            //             initialValues={initialValues}
            //             onChange={async (val: any) => {
            //               const { onFieldChange } = formField;
            //               if (props?.field?.onChange) {
            //                 props?.field?.onChange(val);
            //               }
            //               handleAutosave({ [name]: val });
            //               if (onFieldChange) {
            //                 onFieldChange(val);
            //               }
            //             }}
            //           />
            //         );
            //       }}
            //     />
            //   );
            case FieldTypes.upload:
              return (
                <FileUpload
                  key={`${name}${idx}`}
                  {...formField}
                  {...nonControlledProps}
                />
              );
            case FieldTypes.phone:
              return (
                <InputField
                  key={`${name}${idx}`}
                  formatPhone={true}
                  {...formField}
                  {...nonControlledProps}
                  initialValue={initialValues[name]}
                />
              );
            case FieldTypes.fein:
              return (
                <InputField
                  key={`${name}${idx}`}
                  formatFEIN={true}
                  {...formField}
                  {...nonControlledProps}
                  initialValue={initialValues[name]}
                />
              );
            case FieldTypes.currency:
              return (
                <InputField
                  key={`${name}${idx}`}
                  formatCurrency={true}
                  {...formField}
                  {...nonControlledProps}
                />
              );
            case FieldTypes.currency_number:
              return (
                <InputField
                  key={`${name}${idx}`}
                  formatCurrencyNumber={true}
                  {...formField}
                  {...nonControlledProps}
                />
              );
            case FieldTypes.no_render:
              // eslint-disable-next-line array-callback-return
              return;
            case FieldTypes.custom_render:
              const { getCustomComponent, CustomComponent } = formField;
              if (getCustomComponent) {
                return getCustomComponent({ setValue });
              } else {
                return CustomComponent;
              }
            case FieldTypes.disabled_input:
              return (
                <InputField
                  key={`${name}${idx}`}
                  {...formField}
                  {...nonControlledProps}
                  initialValue={initialValues[name]}
                  disabled={true}
                />
              );
            case FieldTypes.percentage:
              return (
                <InputField
                  key={`${name}${idx}`}
                  {...formField}
                  {...nonControlledProps}
                  initialValue={initialValues[name]}
                  isPercentage={true}
                />
              );
            case FieldTypes.input:
            default:
              return (
                <InputField
                  key={`${name}${idx}`}
                  {...formField}
                  {...nonControlledProps}
                  initialValue={initialValues[name]}
                />
              );
          }
        })}
      </StyledFormContentWrapper>
      {getCustomButtonRow ? (
        // @ts-ignore
        <>{getCustomButtonRow({ validateOnEvent })}</>
      ) : (
        !hideFormSubmit && (
          <Section $alignItems="center">
            {renderWithoutFormTags ? (
              <StyledButton
                label={formSubmitLabel}
                btn_type="primary"
                onClick={handleSubmit(onSubmit)}
                isDisabled={submitDisabled}
              />
            ) : (
              <StyledButton
                label={formSubmitLabel}
                btn_type="primary"
                type="submit"
                loading={formSubmitLoading}
                isDisabled={submitDisabled}
              />
            )}
          </Section>
        )
      )}
    </>
  );
};

const FormContentRenderer = (props: IContentRenderer) => {
  const {
    formSchema,
    /*
     * @ onFormSubmit
     * This is the submit function specific to each form
     * */
    onFormSubmit,
    onFormChange,
    onFormValidate,
    initialValues = {},
    formContentWrapperStyle = {},
    $toggleModal,
    autosave = false,
    hideFormSubmit = false,
    id,
    renderWithoutFormTags = false,
    formTitle,
    additionalProps,
    dropzoneProps,
    formFields,
    handleUpdateEntity,
    formData,
  } = props;
  /*
   * @ mode: onChange | onBlur | onSubmit | onTouched | all = 'onSubmit'
   * https://react-hook-form.com/api/useform/
   * */
  const getResolver = () => {
    if (formSchema) {
      return yupResolver(formSchema);
    } else {
      const defaultSchema = getDefaultFormSchema(formFields);
      return yupResolver(defaultSchema);
    }
  };

  const replaceNull = (values: any) => {
    const keys = Object.keys(values || {});
    let result: any = {};
    keys?.map((k: string) => {
      const val = values[k];
      result[k] = !val ? undefined : val;
    });
    return result;
  };

  const useFormProps = {
    mode: "all",
    resolver: getResolver(),
    defaultValues: replaceNull(initialValues),
  };

  // @ts-ignore
  const formProps = useForm<any>(useFormProps);

  const {
    /*
     * @ handleSubmit
     * - runs validation and only triggers onSubmit if valid
     * - also passes current form data to onSubmit
     * */
    handleSubmit,
    formState,
    trigger,
    reset,
    watch,
  } = formProps;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues?.primary_dot]);

  const onSubmit = (formData: any) => {
    if (onFormSubmit) {
      onFormSubmit(formData);
    }
    if ($toggleModal) {
      $toggleModal();
    }
  };

  const handleSaveErrors = (formErrs?: any) => {
    if (onFormValidate && formErrs) {
      onFormValidate(formErrs);
      return formErrs;
    } else if (onFormValidate && autosave) {
      const formErrors = formState.errors;
      const valErrKeys = Object.keys(formErrors);
      const errors = {
        numErr: valErrKeys.length,
        errFields: valErrKeys,
      };
      onFormValidate(errors);
      return errors;
    }
  };

  useEffect(() => {
    trigger().then(() => {
      handleSaveErrors();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const composedFormContentWrapperStyle =
    !onFormSubmit || hideFormSubmit
      ? {
          marginBottom: 0,
          ...formContentWrapperStyle,
        }
      : formContentWrapperStyle;

  const saveOnFieldChange = (data: any) => {
    if (onFormChange) {
      onFormChange(data);
    } else if (onFormSubmit) {
      onFormSubmit(data);
    }
  };

  const handleAutosave = (data: any) => {
    if (autosave) {
      saveOnFieldChange(data);
    } else {
      onFormChange && onFormChange(data);
    }
    if (validateOnEvent) {
      validateOnEvent()?.then((response) => handleSaveErrors(response));
    }
  };

  const validateOnEvent = () => {
    if (onFormValidate && autosave) {
      return trigger().then(() => handleSaveErrors());
    }
  };

  const debouncedAutosave = useMemo(
    () => debounce(handleAutosave, 600),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const requiredFields: Array<string> = []; // getRequiredFieldsListFromSchema(formSchema);

  const { errors, touchedFields } = formState;
  const additionalFormProps = {
    onSubmit,
    handleSaveErrors,
    handleAutosave,
    saveOnFieldChange,
    validateOnEvent,
    debouncedAutosave,
    requiredFields,
    composedFormContentWrapperStyle,
    formProps: {
      ...formProps,
      errors,
      touchedFields,
    },
  };
  return renderWithoutFormTags ? (
    <Section>
      {formTitle && (
        <StyledFormSectionTitle>{formTitle}</StyledFormSectionTitle>
      )}
      <ContentRenderer
        {...props}
        {...additionalFormProps}
        additionalProps={additionalProps}
        dropzoneProps={dropzoneProps}
        handleUpdateEntity={handleUpdateEntity}
        formData={formData}
      />
    </Section>
  ) : (
    <Section>
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        {formTitle && (
          <StyledFormSectionTitle>{formTitle}</StyledFormSectionTitle>
        )}
        <ContentRenderer
          {...props}
          {...additionalFormProps}
          additionalProps={additionalProps}
          dropzoneProps={dropzoneProps}
          handleUpdateEntity={handleUpdateEntity}
          formData={formData}
        />
      </form>
    </Section>
  );
};

export default FormContentRenderer;
