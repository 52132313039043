import {
  getReactSelectCustomStyles,
  StyledFieldWrapper,
  StyledFormWarnings,
} from "../styled-form-components";
import Select, { components } from "react-select";
import React, { useEffect } from "react";
import { Section } from "../../layout";
import { isEmpty } from "lodash";
import { FieldLabelComponent, FormContentRenderer } from "../";
import { ErrorsHandler } from "../errors.handler";
import { getStatesOptions } from "../../../utils";
import { FieldTypes } from "../../../@types";

export const NoOptionsMessage = ({
  noOptionsText = "No Options",
  ...restOfProps
}: any) => {
  return (
    <components.NoOptionsMessage {...restOfProps}>
      <span className="custom-css-class">{noOptionsText}</span>
    </components.NoOptionsMessage>
  );
};

const msgStyles = {
  fontWeight: "bold",
  color: "var(--primary-black)",
};

export const DropdownSelectBase = ({
  options,
  value,
  style = {},
  noOptionsText,
  isInvalid = false,
  isDisabled = false,
  nonControlledProps,
  name,
  ...restOfProps
}: any) => {
  const reactSelectCustomStyles = getReactSelectCustomStyles(
    isInvalid,
    isDisabled
  );

  const isOption =
    typeof value === "object"
      ? !!Object.keys(value || {})?.includes("value")
      : false;

  const { setValue } = nonControlledProps || {};
  useEffect(() => {
    if (value !== null && !isOption) {
      const opt = options?.find((o: any) => o?.value === value);
      if (opt) {
        setValue(
          name,
          options?.find((o: any) => o?.value === value),
          {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <Select
      options={options === "States" ? getStatesOptions() : options}
      components={{
        IndicatorSeparator: () => null,
        NoOptionsMessage: (props: any) => (
          <NoOptionsMessage
            noOptionsText={noOptionsText}
            {...props}
            {...restOfProps}
          />
        ),
      }}
      isDisabled={isDisabled}
      styles={{
        ...reactSelectCustomStyles,
        ...style,
        noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
      }}
      value={isOption ? value : options?.find((o: any) => o?.value === value)}
      {...restOfProps}
    />
  );
};

export const DropdownSelect = (props: any) => {
  const {
    options,
    label,
    errors,
    touchedFields,
    name,
    field,
    hint,
    info,
    noOptionsText,
    nonControlledProps,
    useStatesOptions = false,
    width = "100%",
    style = { font: "-moz-pull-down-menu", fontFamily: "Roboto" },
    value,
    defaultValue,
    required = false,
    subQuestions,
    formData,
    dropzoneProps,
    autocompleteProps,
    handleUpdateEntity,
  } = props;
  const { watch } = nonControlledProps || {};
  const fieldError = errors && errors[name];
  const fieldVal = options?.find((o: any) => o?.value === (field?.value || field?.value?.value)) || watch ? watch(name) : undefined;
  const fieldTouched =
    (touchedFields && touchedFields[name]) ||
    (!isEmpty(fieldVal) && !(fieldVal === null) && !(fieldVal === undefined));
  const showError = fieldError && fieldTouched;
  const { getFieldState, validateOnEvent, setValue, onFormSubmit } =
    nonControlledProps || {};
  const fieldState = getFieldState && getFieldState(name);

  const wrapperProps = {
    style,
    width,
  };

  useEffect(() => {
    if (!fieldVal) {
      const targetVal = value ? value : defaultValue;
      if (setValue) {
        setValue(name, targetVal, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldVal]);

  return (
    <StyledFieldWrapper {...wrapperProps}>
      <FieldLabelComponent
        label={label}
        info={info}
        hint={hint}
        isRequired={required}
      />
      <Section $flexDirection="row" $justifyContent="flex-start">
        <DropdownSelectBase
          options={useStatesOptions ? getStatesOptions() : options}
          components={{
            IndicatorSeparator: () => null,
            NoOptionsMessage: (props: any) => (
              <NoOptionsMessage noOptionsText={noOptionsText} {...props} />
            ),
          }}
          value={fieldVal}
          {...props}
          isInvalid={fieldState?.invalid}
          isDisabled={props?.isDisabled}
          onFocus={validateOnEvent}
          onBlur={() => {
            props?.field?.onBlur();
            if (validateOnEvent) {
              validateOnEvent();
            }
          }}
        />
      </Section>
      {showError && (
        <StyledFormWarnings style={{ marginTop: "5px" }}>
          {ErrorsHandler(fieldError, label)}
        </StyledFormWarnings>
      )}
      {!isEmpty(subQuestions) &&
        subQuestions.map((q: any, idx: number) => {
          const { displayIf, mappedField, type, name, fieldType } = q || {};
          const fieldName = name || mappedField;
          const contentType = fieldType || type;
          if (
            (displayIf?.condition === "equal" &&
              field?.value === displayIf?.value) ||
            (displayIf?.condition === "oneOf" &&
              displayIf?.value?.includes(field?.value?.value || field?.value))
          ) {
            const dv =
              defaultValue ||
              (!isEmpty(formData) && formData?.[fieldName]) ||
              undefined;
            const additionalSubQProps =
              type === FieldTypes.dropzone
                ? {
                    files: formData[fieldName] || [],
                    ...dropzoneProps,
                  }
                : {};
            return (
              <Section
                key={fieldName + idx}
                style={{
                  padding: "0 45px 0 20px",
                  borderLeft: "2px solid var(--primary1)",
                  marginLeft: 45,
                  marginTop: 10,
                }}
              >
                <FormContentRenderer
                  renderWithoutFormTags={true}
                  formFields={[
                    {
                      name: mappedField || name,
                      fieldType: contentType,
                      defaultValue: dv,
                      ...autocompleteProps,
                      ...q,
                      ...additionalSubQProps,
                      showTotalPowerUnits: q?.addressType?.includes("_garage"),
                    },
                  ]}
                  handleUpdateEntity={handleUpdateEntity}
                  initialValues={formData}
                  autosave={true}
                  onFormSubmit={onFormSubmit}
                  hideFormSubmit={true}
                  formData={formData}
                />
              </Section>
            );
          } else {
            return <div key={fieldName}></div>;
          }
        })}
    </StyledFieldWrapper>
  );
};
