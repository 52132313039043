import { useRef } from "react";
import { Chart } from "react-chartjs-2";
import * as ChartGeo from "chartjs-chart-geo";
import {
  Chart as ChartJS,
  CategoryScale,
  Tooltip,
  Title,
  Legend,
} from "chart.js";
import { isEmpty } from "lodash";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChartGeo.ChoroplethController,
  ChartGeo.ProjectionScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature
);

export const GeoMap = ({ base_geo, data, id }: any) => {
  const chartRef = useRef();

  const updatedData =
    data?.map((e: any) => ({
      ...e,
      source: "incident",
    })) || [];
  const geo_data = [...base_geo, ...updatedData];

  return base_geo && !isEmpty(base_geo) ? (
    <Chart
      id={id}
      ref={chartRef}
      type="choropleth"
      data={{
        labels: geo_data.map((d: any) => d.properties.name),
        datasets: [
          {
            outline: geo_data,
            label: "States",
            data: geo_data?.map((d: any) => ({
              feature: d,
              value: Math.random() * 10,
            })),
            // @ts-ignore
            backgroundColor: (context: any) => {
              if (context?.element?.feature?.source === "incident") {
                return "rgba(234, 23, 55, 1)";
              }
              return "rgba(237, 242, 251, 1)";
            },
          },
        ],
      }}
      options={{
        showOutline: true,
        showGraticule: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                const state = context.element.feature.properties.STUSPS;
                return `${context.element.feature.properties.NAME}${
                  state ? `(${state})` : ""
                }`;
              },
            },
          },
          legend: {
            display: false,
          },
        },
        hover: {
          mode: "nearest",
        },
      }}
    />
  ) : (
    <></>
  );
};
