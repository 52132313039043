import { Card, Section } from "../layout";
import { devices } from "../../utils";
import styled from "styled-components";
import { isEmpty } from "lodash";

export const headerCardStyle = {
  backgroundColor: "var(--primary3)",
  color: "white",
  $alignItems: "center",
};

const StyledCard = styled(Card)`
  height: ${({ height }: any) => (height ? height : "auto")};
  padding: 2em 1.8em;
  align-items: flex-start;
  margin: 0 0 1.5em;
  @media ${devices.mobileS} {
    width: 100%;
  }
  @media ${devices.tablet} {
    width: ${({ responsive, width }) =>
      responsive?.tablet ? responsive.tablet : width ? width : "100%"};
  }
  @media ${devices.laptop} {
    width: ${({ responsive, width }) =>
      responsive?.laptop ? responsive.laptop : width ? width : "100%"};
  }
  @media ${devices.laptopL} {
    width: ${({ responsive, width }) =>
      responsive?.laptopL ? responsive.laptopL : width ? width : "100%"};
  }
`;

const StyledLabel = styled.span`
  font-weight: bold;
  text-align: left;
`;

interface ICard {
  label: string;
  width?: string;
  style?: object;
  responsive?: object;
  childComponent?: JSX.Element;
  height?: string;
}

interface ICardsRenderer {
  cards: Array<ICard>;
}

export const CardsRenderer = ({ cards }: ICardsRenderer) => {
  return (
    <Section
      $flexDirection="row"
      $flexWrap="wrap"
      $justifyContent="space-between"
    >
      {cards?.map((card: ICard, idx: number) => {
        const { label, childComponent, height, ...otherProps } = card;
        return (
          <StyledCard key={idx} {...otherProps} $justifyContent="center">
            {!isEmpty(label) && <StyledLabel>{label}</StyledLabel>}
            {childComponent && (
              <Section style={{ height: height }} $justifyContent="center">
                {childComponent}
              </Section>
            )}
          </StyledCard>
        );
      })}
    </Section>
  );
};

export const BaseCardWithHeaderWrapper = styled(Section)`
  margin-right: 15px;
  height: 100%;
`;

export const BaseCardWithHeaderTop = styled(Section)`
  padding: 1.5em 2em;
  background-color: var(--background-darker-grey);
  justify-content: center;
  align-items: center;
`;

export const BaseCardWithHeaderBottom = styled(Section)`
  padding: 1.5em 2em;
  border: 1px solid var(--background-darker-grey);
  height: 100%;
`;
