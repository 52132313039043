import GaugeChart from "react-gauge-chart";
import { Section } from "../layout";
import { LoadingSpinner } from "../loading-spinner";
import styled from "styled-components";
import { Modal } from "../modal";
import { ChartRenderer } from "../charts";
import { ChartTypes } from "../../@types";

interface IGaugeProps {
  id: string;
  value: number;
  chartPerRow?: number;
  threshold?: number;
  title?: string;
  loading?: boolean;
  measure?: number;
  trends_data?: any;
}

const StyledShowTrendsButton = styled(Section)`
  background-color: var(--primary3);
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  padding: 6px 10px;
  width: 100px;
  margin-top: 15px;
  cursor: pointer;
  text-align: center;
`;

const getMonthLabels = (month: number) => {
  const monthsDict = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthsDict[month];
};

/*
 * TODO:
 * This gauge component is specific to the On-Road Performance charts.
 * Should be refactored for modularity -- not urgent.
 */
const Gauge = ({
  id,
  value,
  chartPerRow,
  threshold = 1,
  title,
  loading,
  measure,
  trends_data,
}: IGaugeProps) => {
  const exceedsThreshold = value > threshold;
  const activeColor = exceedsThreshold && !loading ? "#F07167" : "black";

  const getArcsLength = (threshold: number) => [
    threshold / 3,
    threshold * (2 / 3),
    100 - threshold,
  ];

  const trend_property = `${id}_percentile_rank`;

  const trend_property_data =
    trends_data && trends_data.map((e: any) => e[trend_property]);
  const months: Array<string> = [];
  const mths =
    trends_data &&
    trends_data.forEach((e: any) => {
      const mth = e?.record_date && new Date(e?.record_date).getMonth();
      const yr =
        e?.record_date && new Date(e?.record_date).getFullYear().toString();
      const val = `${getMonthLabels(mth)}'${yr?.substring(2)}`;
      if (!months.includes(val)) {
        months.push(val);
      }
    });
  const numMthsExceedThreshold =
    trend_property_data &&
    trend_property_data.filter((e: number) => e > threshold).length;
  const data = {
    labels: Array.isArray(mths) ? mths.reverse() : [],
    datasets: [
      {
        label: title || "",
        data: Array.isArray(trend_property_data)
          ? trend_property_data.reverse()
          : [],
        fill: false,
        borderColor: exceedsThreshold
          ? "rgb(240, 113, 103)"
          : "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Threshold",
        data: [
          threshold,
          threshold,
          threshold,
          threshold,
          threshold,
          threshold,
        ],
        fill: false,
        borderColor: "rgb(78, 78, 78)",
        tension: 0.1,
      },
    ],
  };

  return (
    <Section
      style={{
        width: chartPerRow ? `${(1 / chartPerRow) * 100}%` : "100%",
        color: "var(--koffie-grey)",
        padding: "0 5px",
      }}
    >
      <Section
        style={{
          border: `2px solid ${exceedsThreshold ? "#F07167" : "white"}`,
          borderRadius: "8px",
          backgroundColor: exceedsThreshold ? "rgba(240, 113, 103, 0.06)" : "",
          padding: exceedsThreshold ? "0 8px 35px 8px" : "0 8px",
        }}
      >
        {title && (
          <Section
            style={{ height: "75px", textAlign: "center" }}
            $alignItems="center"
            $justifyContent="center"
          >
            <h3 style={{ color: activeColor }}>{title}</h3>
          </Section>
        )}
        {loading && !value && value !== 0 ? (
          <Section $alignItems="center">
            <LoadingSpinner />
          </Section>
        ) : (
          <Section $alignItems="center">
            <GaugeChart
              id={id}
              nrOfLevels={420}
              arcsLength={getArcsLength(threshold) || [30, 50, 20]}
              colors={
                value === -1
                  ? ["#bababa", "#ededed", "#969696"]
                  : ["#7FD6CB", "#FEEBCA", "#F07167"]
              }
              percent={value / 100}
              arcPadding={0.03}
              hideText={true}
            />
            <span
              style={{
                color: activeColor,
                fontWeight: "bold",
                fontSize: "1.2em",
              }}
            >
              {value === -1 ? "Insufficient Data" : `${Math.round(value)}%`}
            </span>
            {(measure || measure === 0) && (
              <span>{`Measure: ${
                Math.round((measure + Number.EPSILON) * 100) / 100
              }`}</span>
            )}
            {threshold && (
              <span
                style={{ color: "var(--koffie-grey)" }}
              >{`Threshold: ${threshold}%`}</span>
            )}
            {(numMthsExceedThreshold || numMthsExceedThreshold === 0) && (
              <span
                style={{
                  marginTop: "15px",
                  fontStyle: "italic",
                  fontWeight: numMthsExceedThreshold > 0 ? "bold" : "",
                  color:
                    numMthsExceedThreshold > 0
                      ? "#F07167"
                      : "var(--inactive-grey)",
                  textAlign: "center",
                  fontSize: "0.75em",
                }}
              >
                {numMthsExceedThreshold === 0
                  ? `Percentile rank has not recently exceeded threshold.`
                  : `Percentile rank exceeded threshold for ${numMthsExceedThreshold}/${months.length} months.`}
              </span>
            )}
            {/*<Modal*/}
            {/*  title={`${title} Trends`}*/}
            {/*  Trigger={({ onClick }: any) => {*/}
            {/*    return (*/}
            {/*      <StyledShowTrendsButton onClick={onClick}>*/}
            {/*        TRENDS*/}
            {/*      </StyledShowTrendsButton>*/}
            {/*    );*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <ChartRenderer*/}
            {/*    chartId={`${id}_trends`}*/}
            {/*    chartData={data}*/}
            {/*    chartType={ChartTypes.line}*/}
            {/*  />*/}
            {/*</Modal>*/}
          </Section>
        )}
      </Section>
    </Section>
  );
};

export default Gauge;
