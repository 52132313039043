export const DateFormatter = (
  dateStr: string,
  timestamp = false,
  convertFromUTC = true,
  shortFormat = false
) => {
  let date;
  if (convertFromUTC) {
    date = new Date();
    const utcDate = new Date(dateStr);
    date.setUTCFullYear(utcDate.getFullYear());
    date.setUTCMonth(utcDate.getMonth());
    date.setUTCDate(utcDate.getDate());
    date.setUTCHours(utcDate.getHours());
    date.setUTCMinutes(utcDate.getMinutes());
  } else {
    const isYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/.test(dateStr);

    if (isYYYYMMDD) {
      const dateParts = dateStr.split("-") || [];
      date = new Date(
        Number(dateParts[0]),
        // @ts-ignore
        Number(dateParts[1] - 1),
        Number(dateParts[2])
      );
    } else {
      date = new Date(dateStr);
    }
  }

  return timestamp
    ? date.toLocaleDateString("en-US", {
        timeZone: "America/New_York",
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      })
    : shortFormat ? date.toLocaleDateString("en-US", {
        timeZone: "America/New_York",
        year: "numeric",
        month: "short",
        day: "numeric",
      }) : date.toLocaleDateString("en-US", {
        timeZone: "America/New_York",
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
};
